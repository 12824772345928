import { useEffect } from "react";
import logo from "./logo.svg";

export default function App() {
  useEffect(() => {
    const root = document.querySelector(":root");

    root!.classList.add("h-full");
    document.body.classList.add("h-full");
    document.getElementById("root")!.classList.add("h-full");
    return () => {
      root!.classList.remove("h-full");
      document.body.classList.remove("h-full");
      document.getElementById("root")!.classList.remove("h-full");
    };
  }, []);

  return (
    <>
      <div className="flex min-h-full flex-col bg-white pt-16 pb-12">
        <main className="mx-auto flex w-full max-w-7xl flex-grow flex-col justify-center px-4 sm:px-6 lg:px-8">
          <div className="flex flex-shrink-0 justify-center">
            <div className="inline-flex">
              <span className="sr-only">Tin Bee</span>
              <img className="h-48 w-auto" src={logo} alt="Tin Bee Logo" />
            </div>
          </div>
          <div className="py-16">
            <div className="text-center">
              <p className="text-base font-semibold text-indigo-600">Coming Soon</p>
              <h1 className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl font-serif">Tin Bee</h1>
              <p className="mt-2 text-base text-gray-500">We can't wait to share what we are building with you.</p>
            </div>
          </div>
        </main>
        <footer className="mx-auto w-full max-w-7xl flex-shrink-0 px-4 sm:px-6 lg:px-8">
          <nav className="flex justify-center space-x-4">
            <p className="text-sm font-medium text-gray-500 hover:text-gray-600">Contact Support</p>
            <span className="inline-block border-l border-gray-300" aria-hidden="true" />
            <p className="text-sm font-medium text-gray-500 hover:text-gray-600">Status</p>
            <span className="inline-block border-l border-gray-300" aria-hidden="true" />
            <p className="text-sm font-medium text-gray-500 hover:text-gray-600">
              <a href="https://twitter.com/tinbeeco" target="_blank" rel="noreferrer">
                Twitter
              </a>
            </p>
          </nav>
        </footer>
      </div>
    </>
  );
}
